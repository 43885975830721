import styled from '@emotion/styled';
import tw from 'twin.macro';
import { GatsbyImage } from 'gatsby-plugin-image';

const Container = styled.article`
  ${tw`flex flex-col h-auto items-center`}

  & h1 {
    ${tw`text-white text-4xl text-center font-light leading-tight w-11/12 pb-2`}
  }

  & h2 > span {
    padding: 0px 2px;
    ${tw`text-white font-thin text-base`}

    & > b {
      ${tw`font-bold`}
    }
  }
`;

const ImgStyled = styled(GatsbyImage)`
  ${tw`w-full my-6`}
`;

export { Container, ImgStyled };
