import React, { useEffect, useContext, memo } from 'react';
import { CompanyContext } from '../../context/company.context';
import { CreditcardContext } from '../../context/creditcard.context';
import { navigateToError, redirectWhenStatusPageIsNotValid } from '../../utils/navigate';
import { LoadingComponent } from '../../components/loading';
import pages from '../../constants/pages.json';
import Layout from '../../components/responsive/layout';
import Portrait from '../../components/responsive/portrait';
import { Counter } from '../../components/counter';
import { diffTwoDatesInSeconds as diff, isGreaterThan, isTrue } from '../../utils/functions';
import { Container } from '../../styles/pages/cvv';
import { FormCvv } from '../../components/forms/cvv-card';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const LastFourNumbers = memo(({ digits }: any) => {
  return (
    <span>
      <b>{digits}</b>
    </span>
  );
});

const RenderLayout = ({ render, get, token, user, cardImg }: any) => {
    const { t } = useContext(LangContext);

    const data = useStaticQuery(graphql`
      query {
        allI18NJson {
          nodes {
            locale
            clientId
            CVV {
              TITLE
              SUBTITLE
            }
            PAYMENT_METHODS {
              VISA
              VISA_DEBITO
            }
          }
        }
      }
    `);
    const { getPublicURL } = useContext(CompanyContext);
    const translatePaymentMethod = (text: string) => {
      const replaceWhiteSpaces = (text: string) => text?.replace(/ /g, '_');
      return replaceWhiteSpaces(text);
    };

    if (!render) {
      return <LoadingComponent />;
    }

    return (
      <Layout>
        <Portrait bgImage={getPublicURL(`/theme/assets/images/responsive/portrait-bg-alternative.svg`)}>
          <Container className="content relative">
            <Counter get={get} color="white" />
            <h1>{t(data).CVV.TITLE}</h1>
            <h2>
              <span>
                {t(data).PAYMENT_METHODS[translatePaymentMethod(user?.paymentMethodName)] || user?.paymentMethodName}
              </span>
              <span>{t(data).CVV.SUBTITLE}</span>
              <LastFourNumbers digits={user.lastFourNumbers} />
            </h2>
            <img
              className="w-full my-6"
              loading="eager"
              src={
                cardImg === 'cvv'
                  ? getPublicURL(`/theme/assets/images/cvv/cvv-card-back.png`)
                  : getPublicURL(`/theme/assets/images/cvv/cvv-card-amex.png`)
              }
            />
            <FormCvv get={get} token={token} user={user} />
          </Container>
        </Portrait>
      </Layout>
    );
  }

const CvvPage = ({ get, getToken }: PageProps) => {
  const { infoUser, timeStamp, getCvv } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();
  const { cvvSize } = infoUser;

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else {
      getCvv?.(token);
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp, {
        expired: {
          state: {
            messageMiddleFrom: pages.USER.UPDATE_USER_DETAIL,
          },
        },
      });
    }
  }, [infoUser, timeStamp]);

  const cardImg = ['cvv', 'cvvAmex'][+!!(cvvSize >> 2)];
  const render = [allValid, !!diff(timeStamp ?? '', infoUser?.expirationTime ?? ''), !infoUser.status].every(isTrue);
  return <RenderLayout render={render} get={get} token={token} user={infoUser} cardImg={cardImg} />;
};

export default CvvPage;
