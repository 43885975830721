import React, { useState, ChangeEvent, useContext } from 'react';
import { useFormik, FormikValues } from 'formik';
import { css } from '@emotion/react';
import * as Yup from 'yup';
import { FormProps } from './models';
import { ArticleStyled } from './styles';
import { Input } from '../inputs';
import { MASK_VERIFICATION_CODE, MASK_CHAR_CARD, MASK_VERIFICATION_CODE_4 } from '../../utils/masks';
import { CreditcardContext } from '../../context/creditcard.context';
import creditCardService from '../../services/endpoints';
import { navigateToSuccess, navigateToFailed, navigateToCustomPage } from '../../utils/navigate';
import pages from '../../constants/pages.json';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../context/lang.context';
import Button from '../button/button';

const regexMaskCard = new RegExp(`${MASK_CHAR_CARD}|\\s`, 'gi');

const FormCvv = ({ token, user }: FormProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          CVV {
            FORM {
              VALIDATION {
                REQUIRED_CVV
                INVALID_CVV_3
                INVALID_CVV_4
              }
              INPUT_CVV
              BUTTON_SUBMIT
            }
          }
        }
      }
    }
  `);

  const { infoUser } = useContext(CreditcardContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { cvvSize } = infoUser;

  const formik = useFormik({
    initialValues: {
      secretCode: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      secretCode: Yup.string()
        .min(
          cvvSize,
          `${cvvSize === 3 ? t(data).CVV.FORM.VALIDATION.INVALID_CVV_3 : t(data).CVV.FORM.VALIDATION.INVALID_CVV_4}`
        )
        .required(t(data).CVV.FORM.VALIDATION.REQUIRED_CVV),
    }),
    onSubmit: async (values: FormikValues) => {
      if (submitting) {
        return;
      }

      setSubmitting(true);
      const { secretCode } = values;
      let state;

      try {
        const { action, url, feedback } = await creditCardService.postCvvInformation(secretCode, user.cardId, {
          token,
        });
        if (action === 'redirect') {
          navigateToCustomPage(`/${url}`)?.();
        } else if (action === 'paid') {
          state = { from: pages.SUCCESS_PAGE.PAID, response: feedback };
          navigateToSuccess({ state })?.();
        } else if (
          /* if we receive an unknown error code we redirect to the generic failed page too */
          (action === 'custom_error' && ['FGS2000', 'FGS1400'].includes(feedback.error_code)) || feedback.error_code) {
          state = { from: pages.FAILED_PAGE.CVV };
          navigateToFailed({ state })?.();
        } else {
          state = { from: pages.SUCCESS_PAGE.CVV };
          navigateToSuccess({ state })?.();
        }
      } catch {
        state = { from: pages.FAILED_PAGE.CVV };
        navigateToFailed({ state })?.();
      }
    },
  });

  return (
    <form autoComplete="false" onSubmit={formik.handleSubmit} className="w-full">
      <ArticleStyled>
        <Input
          maxLength={cvvSize}
          mask={cvvSize === 3 ? MASK_VERIFICATION_CODE : MASK_VERIFICATION_CODE_4}
          title={t(data).CVV.FORM.INPUT_CVV}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.secretCode}
          errorMessage={formik.errors.secretCode}
          onChange={(e: ChangeEvent<any>) => {
            const cardNumber = e.target.value.replace(regexMaskCard, '');
            formik.setFieldValue('secretCode', cardNumber);
          }}
          touchedInput={formik.touched.secretCode}
          onFocus={() => formik.setFieldTouched('secretCode', false)}
          pattern="[0-9]*"
          guide={false}
          suffixIcon={
            !showPassword ? (
              <i className="icon-eye-stroke" />
            ) : (
              <i
                className="icon-eye-open"
                css={css`
                  &::before {
                    font-size: 0.8rem;
                    position: relative;
                    top: -2px;
                  }
                `}
              />
            )
          }
          suffixIconCallback={() => setShowPassword(!showPassword)}
        />
      </ArticleStyled>

      <ArticleStyled className="items-center">
        <Button color={'primary'} type="submit" aria-label={t(data).CVV.FORM.BUTTON_SUBMIT}>
          {t(data).CVV.FORM.BUTTON_SUBMIT}
        </Button>
      </ArticleStyled>
    </form>
  );
};

export { FormCvv };
